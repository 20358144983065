
import { Options, Vue } from "vue-class-component";
import ItemDetale from "@views/page/components/item-detale.vue";
import Page from "@views/page/components/page.vue";
import PageRequest from "@/dataSource/api/pagination-helper/lib/PageRequest";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";
import EnumLogType from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogType";
import EnumLogLevel from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogLevel";
import GetWebsocketLogDetailtResponse from "@/dataSource/api/logger-micro-service/services/models/request/websocket-getter-service/GetWebsocketLogDetailtResponse";

@Options<WsLogsDetailPage>({
  components: {
    Page,
    ItemDetale,
  },
  props: { slotProps: {} },
})
export default class WsLogsDetailPage extends Vue {
  readonly slotProps = {};
  pagination: PageRequest;
  ConnectionId: string = "";
  item: GetWebsocketLogDetailtResponse = null;

  isLoading = false;
  filterModel: FilterModel = new FilterModel();
  filterModelView: FilterModel = null;

  error1 = {
    isSuccess: true,
    errorMessage: "",
  };
  error2 = {
    isSuccess: true,
    errorMessage: "",
  };

  getUtc = getUtc;

  async mounted() {
    this.ConnectionId = String(this.$route.params.id);
    await this.getLogDetails();
    this.isLoading = false;
  }
  /**
   * Разница дат в ms
   * @param date1
   * @param date2
   */
  getms(date1: string, date2: string) {
    try {
      let res = Math.abs((new Date(date2) as any) - (new Date(date1) as any));

      return res || res == 0 ? `(${res})  ms` : "-";
    } catch {
      return "-";
    }
  }
  getLocalStorageInfo() {
    if (localStorage.contentLogsRequestId) {
      this.ConnectionId = localStorage.getItem("contentLogsRequestId");
      this.getLogDetails();
      // this.getLogData();
    } else {
      this.$router.back();
    }
  }

  async getLogDetails() {
    this.isLoading = true;
    let request;
    try {
      this.item = await this.$api.WebSocketGetterService.getLogDetailsAsync(
        `"${this.ConnectionId}"`
      );
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.error1.isSuccess = false;
      this.error1.errorMessage = error;
      this.isLoading = false;
    }

    if (request) this.item = request;
  }

  getLogType(type: EnumLogType) {
    return EnumLogType[type];
  }
  getLogLevel(level: EnumLogLevel) {
    return EnumLogLevel[level];
  }
}
